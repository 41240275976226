import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  List,
  ListSubheader,
  CardActionArea,
} from "@mui/material";
import { translations } from "./translator";
import jsonData from "./KCD82.json";

const ConfiguratorListSubheader = () => {
    const [price, setPrice] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [data, setData] = useState(jsonData);
    const [config, setConfig] = useState({});
    const [selectedValues, setSelectedValues] = useState({});

    const handleRadioChange = (attribute, value) => {
        setConfig((prevConfig) => ({
            ...prevConfig,
            [attribute]: value,
        }));
    
        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [attribute]: value,
        }));
    };    

    const handleSubmit = () => {
        console.log("Submitted configuration:", config);
        // You can perform further actions with the submitted configuration here
    };

    useEffect(() => {
        // Initialize with firstConfiguration if available
        const initialConfig = data.firstConfiguration
            ? JSON.parse(data.firstConfiguration)
            : [];
    
        // Extract selected values from initialConfig
        const initialSelectedValues = initialConfig.reduce((acc, item) => {
            acc[item.attr] = item.value;
            return acc;
        }, {});
    
        // Set the configuration and selected values in state
        setConfig(initialSelectedValues);
        setSelectedValues(initialSelectedValues);
    
        // Access price and currency
        const price = data.Price?.Price;
        const currency = data.Price?.Currency;
    
        // Set the price and currency in state
        setPrice(price);
        setCurrency(currency);
    }, [data]);    

    const renderRadioGroups = () => (
        <List subheader={<li />} sx={{ width: "100%", bgcolor: "background.paper" }}>
            {Object.keys(data.Attributes).map((attribute, index) => (
                <li key={attribute}>
                    <ul style={{ listStyle: "none", margin: "0", padding: "0" }}>
                        <ListSubheader
                            sx={{
                                bgcolor: "rgb( 250, 250, 250)",
                                position: "sticky",
                                top: 0,
                                zIndex: 10,
                                paddingTop: "8px",
                                paddingBottom: "8px",
                            }}
                        >
                            <Typography variant="h6" color={"black"} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {translations[data.Attributes[attribute]?.Name] || data.Attributes[attribute]?.Name} - {data.Attributes[attribute]?.groups[0]?.values[selectedValues[attribute]]?.n || ""}
                            </Typography>
                        </ListSubheader>
                        <Paper square={false} elevation={0} sx={{ px: 2, py: 4, m: 0 }}>
                            <Grid container spacing={2}>
                                {data.Attributes[attribute]?.groups?.[0]?.values &&
                                    Object.entries(data.Attributes[attribute].groups[0].values).map(([value, { n, price }]) => (
                                        <Grid item key={value} xs={6} sm={4} md={4}>
                                            <CardActionArea
                                                onClick={() => handleRadioChange(attribute, value)}
                                                sx={{
                                                    '&:hover': {
                                                        '& .MuiCardActionArea-focusHighlight': {
                                                            borderRadius: '10px', // Change the border radius on hover
                                                        },
                                                    },
                                                }}
                                            >
                                                <Card
                                                    variant="outlined"
                                                    elevation={0}
                                                    sx={{
                                                        width: { xs: '100%', sm: 'calc(100% - 4px)' },
                                                        height: 120,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        border: config[attribute] === value ? '1px solid rgba(0, 0, 0, .5)' : '1px solid white',
                                                        position: 'relative',
                                                        borderRadius: '10px',
                                                    }}
                                                >
                                                    {/* Card Media - Display a rectangle (120x80px) */}
                                                    <div
                                                        style={{
                                                            width: '100%',
                                                            minHeight: '80px',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                                        }}
                                                    />

                                                    {/* Card Content - Display the option name */}
                                                    <CardContent
                                                        sx={{ textWrap: 'nowrap', textAlign: 'center', padding: '10px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                                    >
                                                        <Typography variant="caption">{n}</Typography>
                                                    </CardContent>

                                                    {/* Price - Display the price in the top-right corner */}
                                                    {price !== undefined && price !== 0 && (
                                                        <Typography
                                                            variant="caption"
                                                            sx={{ position: 'absolute', backgroundColor: 'black', color: 'white', top: '0', right: '0', padding: '4px 6px 0px', textAlign: 'left', }}
                                                        >
                                                            +{price}€
                                                        </Typography>
                                                    )}
                                                </Card>
                                            </CardActionArea>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Paper>
                    </ul>
                </li>
            ))}
        </List>
    ); 

    const renderSummary = () => (
        <Card sx={{ mt: 2, boxShadow: "none" }}>
            <CardContent>
                <Typography variant="h6" pb={2}>
                    Summary:
                </Typography>
                {Object.entries(config).map(([attribute, selectedValue], index) => {
                    const { n, price } =
                        data.Attributes[attribute]?.groups[0]?.values[selectedValue] || {};
                        const translatedAttributeName =
                            translations[data.Attributes[attribute]?.Name] ||
                            data.Attributes[attribute]?.Name;

                    return (
                        <Typography key={index}>
                            {translatedAttributeName}: {n || "No Name"}
                            {price !== undefined && price !== 0 && ` +${price}€`}
                        </Typography>
                    );
                })}
            </CardContent>
        </Card>
    );

    const renderPriceAndCurrency = () => {
        let totalStepPrice = 0;

        return (
            <>
                {price !== null && price !== 0 && (
                    <Paper square elevation={0} sx={{ display: 'flex', justifyContent: 'space-between', zIndex: '10', p: 2, mt: 2, bgcolor: 'rgb( 250, 250, 250)', position: 'sticky', bottom: 0, flexDirection: 'row' }}>
                        <Typography pb={1}>
                            Cena: {price} €
                        </Typography>
                        {Object.entries(config).map(([attribute, selectedValue], index) => {
                            const stepPrice =
                                data.Attributes[attribute]?.groups[0]?.values[selectedValue]?.price || 0;

                            if (stepPrice !== 0) {
                                totalStepPrice += stepPrice;
                                return ` +${stepPrice}€`;
                            } else {
                                return null;
                            }
                        }).filter((stepPrice) => stepPrice !== null).length > 0 && (
                                <Typography pb={2}>
                                    Príplatky: {totalStepPrice} €
                                </Typography>
                            )}
                        <Button
                            variant="outlined"
                            color='inherit'
                            onClick={handleSubmit}
                        >
                            Odoslať
                        </Button>
                    </Paper>
                )}
            </>
        );
    };    

    return (
        <Container maxWidth="xs">
            <Typography variant="h5" pt={2} px={2}>
                Configurator - {data.Sku}
            </Typography>
            <Typography variant="h6" px={2} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {data.Name}
            </Typography>
            <Typography variant="subtitle2" px={2}>
                Rozmery: {data.Parameters.Lenght} x {data.Parameters.Width} x {data.Parameters.Height}mm
            </Typography>
            <Typography variant="subtitle2" px={2} pb={2}>
                Váha: {data.Parameters.Weight}kg
            </Typography>

            {renderRadioGroups()}

            {/* Display summary and price */}
            {renderSummary()}
            {renderPriceAndCurrency()}
        </Container>
    );
};

export default ConfiguratorListSubheader;
