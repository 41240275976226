// translator.js
export const translations = {
    "Colour of the top": "Farba vrchu a dna",
    "Colour of the body": "Farba korpusu",
    "Colour of the front": "Farba čelných plôch",
    "Files drawer": "Zásuvka na dok.",
    "Type of handle": "Úchytky",
    "Dry-wipe whiteboard": "Tabuľa so suchým utieraním"
    // Ďalšie preklady podľa potreby
};
