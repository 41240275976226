import React from 'react';
import Configurator from './components/Configurator';
import ConfiguratorAccordion from './components/ConfiguratorAccordion';
import ConfiguratorStepper from './components/ConfiguratorStepper';
import ConfiguratorStepperVerticall from './components/ConfiguratorStepperVerticall';
import ConfiguratorStepperVertical from './components/ConfiguratorStepperVertical';
import ConfiguratorMobileStepper from './components/ConfiguratorMobileStepper';
import ConfiguratorListSubheader from './components/ConfiguratorListSubheader';

const App = () => {
  return (
    <React.StrictMode>
      {/* <Configurator /> */}
      {/* <ConfiguratorAccordion /> */}
      {/* <ConfiguratorStepper /> */}
      {/* <ConfiguratorStepperVertical /> */}
      {/* <ConfiguratorStepperVerticall /> */}
      {/* <ConfiguratorMobileStepper /> */}
      <ConfiguratorListSubheader />
    </React.StrictMode>
  );
};

export default App;